/**
 * Builds a CloudFormation create stack button
 *
 * See: https://aws.amazon.com/blogs/devops/construct-your-own-launch-stack-url/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const CFNButton = (props) => {
  const data = props.data;
  const templateUrl = `${data?.site?.siteMetadata?.templateUrl}/public/${props.templateName}.yaml`
  const stackUrl = `https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=${props.templateName}&templateURL=${templateUrl}`

  return (
    <div className="launchCfnStack">
      {data.site && (
        <div style={{marginBlockStart: '1em', marginBlockEnd: '1em', display: 'flex', flexDirection: 'row'}}>
          <a href={stackUrl} target="_blank" rel="noreferrer">
            <StaticImage
              src="./images/cloudformation-launch-stack.png"
              alt="Create CloudFormation Stack"
            ></StaticImage>
          </a>
          &nbsp;or&nbsp;
          <a href={templateUrl}>download the template</a>
        </div>
      )}
    </div>
  )
}

const CreateStackButton = props => {
  return (
    <StaticQuery
      query={graphql`
        query TemplateQuery {
          site {
            siteMetadata {
              templateUrl
            }
          }
        }
      `}
      render={data => <CFNButton data={data} {...props} />}
    />
  )
}

export default CreateStackButton
